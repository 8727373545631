#resume {
    padding-top: 20px;

}

#phone {
    text-align: center;
    padding-top: 20px;
    color: whitesmoke;
}

#contactTitle {
    text-align: center;
    padding-top: 20px;
    color: whitesmoke;
}

.bottomIcons {
  margin-left: 40px;
  
}
.bottomIcons:hover {
    color: white!important;
}

