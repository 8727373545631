.wrapperD {
    margin: auto;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgb(34, 22, 22);
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 30px;
    -webkit-box-shadow: 0 8px 9px #393636, 0 8px 9px #393636;
    -moz-box-shadow: 0 8px 9px #393636, 0 8px 9px #393636;
    box-shadow:  0 8px 9px #393636, 0 8px 9px #393636;
}

h1 {
    text-align: center;
}
