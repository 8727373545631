#jumbo {
    height: 400px;
    vertical-align: center;
    text-align: center;
    width: auto;
    background-color: rgb(34, 22, 22);
    border-radius: 30px;
    -webkit-box-shadow: 0 8px 9px #F0C98A, 0 8px 9px #F0C98A;
    -moz-box-shadow: 0 8px 9px #F0C98A, 0 8px 9px #F0C98A;
    box-shadow:  0 8px 9px #F0C98A, 0 8px 9px #F0C98A;
}

#jumbo:hover {
    animation: pulse; 
    animation-duration:1s;
}

#name, #title {
    color: whitesmoke;
}

