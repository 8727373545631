/* custom css */

/* Background from Subtle Patterns */


body { 
    background-image: url("../custom-css/brick.jpg");
}

img {
   
    border-radius: 30px;
    -webkit-box-shadow: 0 8px 9px #393636, 0 8px 9px #393636;
    -moz-box-shadow: 0 8px 9px #393636, 0 8px 9px #393636;
    box-shadow:  0 8px 9px #393636, 0 8px 9px #393636;
    vertical-align: center;
    justify-content: center;
    text-align: center;
}

@media screen and ( max-width: 199px ) {
    img.responsive { width: 80px;  margin: auto; }
    }

@media screen and ( min-width: 200px ) and ( max-width: 242px ) {
    img.responsive { width: 150px;  margin: auto; }
    }

@media screen and ( min-width: 243px ) and ( max-width: 272px ) {
    img.responsive { width: 190px;  margin: auto; }
    }

@media screen and ( min-width: 273px ) and ( max-width: 301px ) {
    img.responsive { width: 220px;  margin: auto; }
    }

@media screen and ( min-width: 302px ) and ( max-width: 329px ) {
    img.responsive { width: 250px;  margin: auto; }
    }

@media screen and ( min-width: 330px ) and ( max-width: 373px ) {
    img.responsive { width: 290px;  margin: auto; }
    }

@media screen and ( min-width: 374px ) and ( max-width: 402px ) {
    img.responsive { width: 307px;  margin: auto; }
    }

@media screen and ( min-width: 403px ) and ( max-width: 454px ) {
    img.responsive { width: 350px;  margin: auto; }
    }

@media screen and ( min-width: 455px ) and ( max-width: 538px ) {
    img.responsive { width: 400px;  margin: auto; }
    }

@media screen and ( min-width: 539px ) and ( max-width: 691px ) {
    img.responsive { width: 470px!important;  margin: auto!important; }
    }

@media screen and ( min-width: 692px ) and ( max-width: 773px ) {
        img.responsive { width: 470px!important;  margin: auto!important; }
        }

@media screen and ( min-width: 774px ) and ( max-width: 887px ) {
    img.responsive { width: 640px!important;  margin: auto!important; }
    }

@media screen and ( min-width: 888px )and ( max-width: 1024px ) {
    img.responsive { width: 400px;  margin: auto; }
    }

@media screen and ( min-width: 1025px ) and ( max-width: 1080px ) {
    img.responsive { width: 430px;  margin: 20px; }
    }

@media screen and ( min-width: 1081px ) {
    img.responsive { width: 450px;  margin: 20px;}
    }
    img.responsive { height: auto; }
   


footer { 
    background-color: #343A40;
    bottom: 0;
    margin: auto;
    text-align: center;
    color: cornsilk;
    
}

a { 
    color: aqua;
}

main {
    background-color: inherit

}

.navbar-dark {
    border-color: none;
}

