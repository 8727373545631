h2 {
    padding: 20px;
    text-align: center
}

button {
    width: 100%;
    box-shadow: 2px 6px slategray;
}

button:hover {
    box-shadow: 6px 11px slategray;
}

img {
    box-shadow: 5px 5px 2px 2px slategray;;
}

.responsive:hover {
    animation: tada; /* referring directly to the animation's @keyframe declaration */
    animation-duration:1s;
}

#fontChange {
    color: black;
    font-size: 18px;
}