#summaryP {
    background-color: rgb(34, 22, 22);
    margin: auto;
    color: black;
    font-size: medium;
    font-weight: 200;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 30px;
    -webkit-box-shadow: 0 8px 9px #393636, 0 8px 9px #393636;
    -moz-box-shadow: 0 8px 9px #393636, 0 8px 9px #393636;
    box-shadow:  0 8px 9px #393636, 0 8px 9px #393636;
}
p, #aboutTitle {
    color: whitesmoke;
}

#summaryP:hover {
        animation: pulse; 
        animation-duration:1s;
    
}