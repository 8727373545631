.wrapper {
    margin: auto;
    padding-left: 5px;
    padding-right: 5px;
    background-color: whitesmoke;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 30px;
}

h1 {
    text-align: center;
}
