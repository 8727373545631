.nav-item > .active {
    border-bottom: 1px solid #333;
    color: white;
}
.nav-item {
    color: whitesmoke;
    /* opacity: 65%; */
    padding: 15px;
    text-align: center;
    margin: auto;

}

.nav-item:hover {
    color: white;
    /* opacity: 100%; */
    border: 1px solid;
    border-color: grey;
    border-radius: 5px;
    cursor: pointer;
}

.nav-item:active {
    color: white;
    /* opacity: 100%; */
    border-color: white;
    cursor: pointer;
    
}

.navbar-toggler {
    border-radius: 10px;
    box-shadow: none;
    margin: auto;
}

.navbar-toggler:hover {
    box-shadow: none;
    border-color: white;
    
}

.navbar {
    clip-path: polygon(50% 100%, -130% 0%, 230% 0);
}

